import { createTheme } from '@mui/material'
import { blue, blueGrey } from "@mui/material/colors"

const workSans = ["Work Sans", "sans-serif"].join(",")

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: blue,
    background: {
      default: blueGrey[50],
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontWeightRegular: 300,
    h1: {
      fontFamily: workSans,
    },
    h2: {
      fontFamily: workSans,
      fontWeight: 500,
      fontSize: "2.441rem",
    },
    h3: {
      fontFamily: workSans,
      fontSize: "1.953rem",
      fontWeight: 500,
    },
    h4: {
      fontFamily: workSans,
      fontSize: "1.563rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.25rem",
      fontFamily: workSans,
      fontWeight: 500,
    },
    h6: {
      fontSize: "1rem",
      fontFamily: workSans,
    },
    button: {
      fontFamily: workSans,
    },
    overline: {
      fontFamily: workSans,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "1.563rem",
      lineHeight: 1.75,
    },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h2: {
          paddingBottom: "1.38rem",
        },
        h3: {
          paddingBottom: "1.38rem",
        },
        overline: {
          display: "block",
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          verticalAlign: "top",
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "md",
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiButton: {
      defaultProps: {
        color: "primary",
      },
    },
  },
})

export default theme